import dynamic from "next/dynamic";

const NavbarComponent = dynamic(() => import('../components/Layout/Navbar'))
const FooterComponent = dynamic(() => import('../components/Layout/Footer'))
const CookieConsentComponent = dynamic(() => import('~/components/CookieConsent'));

import { Box, ChakraProvider, Container, extendTheme } from '@chakra-ui/react';
import PlausibleProvider from 'next-plausible';
import NextNProgress from "nextjs-progressbar";
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { SEOComponent } from '../components/SEO';

import { mode } from "@chakra-ui/theme-tools";

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode("", "#121212")(props),
      }
    })
  },
})
 

function Dangercord({ Component, pageProps, router }) {
  return (
    <PlausibleProvider domain="dangercord.com" customDomain="https://waffles.churton.cloud" selfHosted>
      <SEOComponent/>
      
      <ChakraProvider theme={theme}>

        <NextNProgress
          options={{ easing: "ease", speed: 250 }}
        />
        <Box minH="100vh" display="flex" flexDirection="column" overflowX="hidden">
          <Toaster/>
          
          <NavbarComponent 
            searchHidden={router.pathname === '/'} 
            homeBg={router.pathname === '/'}
          />

          {router.pathname === '/' && (
            <Box py={8}>
              <Component {...pageProps}/>
            </Box>
          )}

          {router.pathname !== '/' && (
            <Container maxW='1250px'>
              <Box py={8} >
                <Component {...pageProps}/>
              </Box>
            </Container>
          )}

          <FooterComponent/>
        </Box>
      </ChakraProvider>
    </PlausibleProvider>
  )
}

export default Dangercord
