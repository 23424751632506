import Head from "next/head";
import { useRouter } from "next/router";

export const SEOComponent = ({ title, description, image }) => {
  const router = useRouter()

  return (
    <Head>
      <title>{title ? `${title} - `: ''}Dangercord</title>
      <meta name="title" content={`${title ? `${title} - `: ''}Dangercord`}/>
      <meta name="description" content={description}/>
      <meta name="theme-color" content="#0a0f1a"/>

      <link rel="canonical" href={'https://dangercord.com' + router.asPath}/>

      <meta property="og:type" content="website"/>
      <meta property="og:title" content={`${title ? `${title} - `: ''}Dangercord`}/>
      <meta property="og:description" content={description}/>

      <meta property="twitter:title" content={`${title ? `${title} - `: ''}Dangercord`}/>
      <meta property="twitter:description" content={description}/>


      <meta name="keywords" content="discord,dangerous discord,dangercord,discord report,discord reporting,discord spammer,discord scammer,report discord user,like discord user,dislike discord user,vote discord user,discord user info,ddub,discord user database,dangerous discord user database"/>

      {image && (
        <>
          <meta property="og:image" content={image}/>

          <meta property="twitter:card" content="summary"/>
        </>
      )}
    </Head>
  );
}